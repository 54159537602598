<template>
  <div>
    <WindowsBottomNavigation 
      v-model="selected"
      :options="menu"
      :foregroundColor="set_color"
      :border-color="set_color"
      backgroundColor="#FFFFFF">
      <template #icon="{ props }">
        <v-img 
          :class="selected === props.id ? 'mb-2' : ''"
          :style="selected === props.id ? '' : 'opacity: 50%;'"
          contain
          width="22"
          height="22"
          alt="amanata" 
          :src="props.icon"
          class="ma-auto cursor-pointer">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular 
                size="20"
                indeterminate 
                :color="set_color">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </template>

      <template #title="{ props }">
        <div
          :style="`color: ${set_color};`">
          {{ props.title }}
        </div>
      </template>
    </WindowsBottomNavigation>
  </div>
</template>

<script>
  import { db } from '@/plugins/Firebase'
  import moment from 'moment'
  export default {
    data () {
      return {
        selected: 1,
        menu: [
          {
            id: 1,
            title: "Beranda",
            icon: require('@/assets/image/footer/home.png'),
            path: { name: "home" }
          },
          {
            id: 2,
            title: "Laporan",
            icon: require('@/assets/image/footer/report.png'),
            path: { name: "report" }
          },
          {
            id: 3,
            title: "Presensi",
            icon: require('@/assets/image/footer/attendance.png'),
            path: { name: "attendance" }
          },
          {
            id: 4,
            title: "Izin & Cuti",
            icon: require('@/assets/image/footer/holiday.png'),
            path: { name: "holiday" }
          },
          {
            id: 4,
            title: "Profil",
            icon: require('@/assets/image/footer/settings.png'),
            path: { name: "profile" }
          }
        ],
        data_routes: {},
        user_id: ''
      }
    },
    components: {
      
    },
    watch: {
      $route: function(val) {
        this.menu.map(obj => {
          if (obj.path.name === val.name) this.selected = obj.id
        })

        if (Object.keys(this.user).length > 0) {
          this.fetchCheck()
        }
      }
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      },
      rps() {
        return this.$vuetify.breakpoint.name
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      this.menu.map(obj => {
        if (obj.path.name === this.$route.name) this.selected = obj.id
      })

      if (Object.keys(this.user).length > 0) {
        this.fetchCheck()
      }
    },
    methods: {
      async fetchCheck () {
        let ref = db.collection('routes').where('user_id', '==', this.user.id)
        await ref.onSnapshot(snapshot => {
          if (snapshot.docChanges().length < 1) {
            this.addFirst()
          } else {
            this.fetchRoutes()
          }
        })
      },
      async addFirst() {
        navigator.geolocation.getCurrentPosition(position => {
          this.data_routes = {
            id: this.user.id,
            code: this.user.code,
            name: this.user.name,
            username: this.user.username,
            email: this.user.email,
            mobilephone: this.user.mobilephone,
            address: this.user.address,
            image_url: this.user.image_url,
            position: this.user.position,
            role: this.user.role,
            unit_id: this.user.unit_id,
            unit_name: this.user.unit_name,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            input_datetime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          }
        })

        if (Object.keys(this.data_routes).length > 0) {
          await db.collection('routes').add({
            user_id: this.user.id
          })
          .then(response => {
            db.collection('routes').doc(response.id).collection('user').add(this.data_routes)
          })
        } 
      },
      async fetchRoutes() {
        let ref = db.collection('routes').where('user_id', '==', this.user.id)
        await ref.onSnapshot(snapshot => {
          snapshot.docs.map((doc) => {
            this.user_id = doc.id

            this.addRoutes()
          })
        })
      },
      async addRoutes() {
        navigator.geolocation.getCurrentPosition(position => {
          this.data_routes = {
            id: this.user.id,
            code: this.user.code,
            name: this.user.name,
            username: this.user.username,
            email: this.user.email,
            mobilephone: this.user.mobilephone,
            address: this.user.address,
            image_url: this.user.image_url,
            position: this.user.position,
            role: this.user.role,
            unit_id: this.user.unit_id,
            unit_name: this.user.unit_name,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            input_datetime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          }
        })


        if (Object.keys(this.data_routes).length > 0) {
          await db.collection('routes').doc(this.user_id).collection('user').add(this.data_routes)
          .then(response => {
            // DATA RESPONSE
            console.log(this.data_routes)
          })
          .catch(error => {
            console.error('Data tidak terkirim: ', error)
          })
        }
      }
    }
  }
</script>

<style scoped>
  .wn-btn-container-foreground {
    max-width: 460px !important;
    height: 56px !important;
    z-index: 1 !important;
  }
</style>