<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat
            color="transparent">
            <v-card-text
              class="pb-1">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                class="no_box"
                solo
                :color="set_color"
                single-line
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>
            </v-card-text>

            <v-card-text>
              <v-list 
                flat 
                three-line 
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 7 : list"
                    :key="index"
                    :loading="process.load"
                    type="list-item-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.load && list.length > 0"
                          @click="selected.doc = item; sheet.detail_doc = true;"
                          :class="index < list.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
                                border-radius: 10px;
                                background: #FFFFFF40;"
                          :style="`border-left: 4px solid ${set_color};`">
                          <!-- <v-list-item-avatar 
                            style="box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;"
                            size="50"
                            class="mr-3 my-3">
                            <v-img
                              alt="amanata"
                              :src="item.image_url === '' ? require('@/assets/image/member_default.jpg') : item.image_url">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar> -->

                          <v-list-item-content>
                            <v-list-item-subtitle
                              class="caption mb-1 d-flex align-center">
                              <v-icon x-small>mdi-calendar-month-outline</v-icon>
                              
                              <span
                                class="ml-1">
                                {{ item.date | date }}
                              </span>
                            </v-list-item-subtitle>

                            <v-list-item-title
                              class="body-1 font-weight-bold">
                              {{ item.name }}
                            </v-list-item-title>

                            <v-list-item-subtitle 
                              class="body-2 line-text-first">
                              <v-divider
                                class="mt-2 mb-3"
                                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />
                                
                              <div
                                class="d-flex align-center">
                                Take Home Pay: 

                                <v-spacer />

                                {{ item.thp | price }}
                              </div>
                            </v-list-item-subtitle>

                            <!-- <v-list-item-subtitle 
                              class="body-2 line-text-first">
                              <v-divider
                                class="my-2"
                                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />
                                
                              <div
                                class="d-flex align-center">
                                Gaji Pokok: 

                                <v-spacer />

                                {{ item.basic_value | price }}
                              </div>
                            </v-list-item-subtitle>


                            <v-list-item-subtitle 
                              class="body-2 line-text-first d-flex align-center">
                              T. Tetap: 

                              <v-spacer />
                              
                              {{ item.fixed_value | price }}
                            </v-list-item-subtitle>

                            <v-list-item-subtitle 
                              class="body-2 line-text-first d-flex align-center">
                              T. Tidak Tetap: 
                              
                              <v-spacer />
                              
                              {{ item.non_fixed_value | price }}
                            </v-list-item-subtitle> -->
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <v-card-text v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  style="border-radius: 8px;"
                  :style="`color: ${set_color};`">
                  Lihat Lebih Banyak
                </v-btn>
              </v-card-text>

              <Empty v-if="!process.load && pagination.total_page < 1"
                margin="my-16"
                size="125"
                message="Riwayat Gaji" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.detail_doc"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title 
              @click="sheet.detail_doc = false"
              class="cursor-pointer pa-4"
              style="border-radius: 0px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;"
              :style="`background: ${set_color};`">
              <v-icon 
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Slip Gaji
              </span>
            </v-card-title>

            <v-card-text v-if="Object.keys(selected.doc).length > 0"
              class="pt-16 mt-3 px-4">
              <div
                class="caption mb-1 d-flex align-center">
                <v-icon x-small>mdi-calendar-month-outline</v-icon>
                
                <span
                  class="ml-1">
                  {{ selected.doc.date | date }}
                </span>
              </div>

              <div
                class="d-flex align-center">
                <div
                  class="body-2">
                  Take Home Pay
                </div>

                <v-spacer />

                <div
                  class="title font-weight-bold black--text">
                  {{ selected.doc.thp | price }}
                </div>
              </div>

              <v-divider
                class="mt-2 mb-6"
                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />

              <div
                class="body-2 font-weight-bold black--text mb-4">
                Slip Gaji:
              </div>

              <div v-if="selected.doc.url.length > 0">
                <div v-if="selected.doc.url.split('.').pop() !== 'pdf'">
                  <v-img
                    contain
                    alt="amanata" 
                    width="100%"
                    height="auto"
                    :src="selected.doc.url"
                    class="ma-auto border-radius">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>

                <div v-else>
                  <iframe 
                    :src="`${selected.doc.url}#toolbar=0`" 
                    allowfullscreen
                    frameborder="0" 
                    width="100%"
                    height="397"
                    style="border-radius: 15px; border: 2px solid #323639;">
                  </iframe>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        sheet: {
          detail_doc: false
        },
        selected: {
          doc: {}
        },
        search: '',
        list: [],
        pagination: {},
        limit: 10,
        process: {
          load: false,

          limit: false
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.process.limit = true
        
        this.fetch('limit')
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          limit: this.limit,
          search: this.search
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/user/profile/history_salary`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {  
            this.list = response.results.data
            this.pagination = response.results.pagination
          } 
        })
      }
    }
  }
</script>