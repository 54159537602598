<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.success"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="amanata"
                  :src="require('@/assets/logo/logo.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold ml-2">
                Ubah Profil Berhasil
              </span>
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-3">
              Terima kasih, Anda telah melengkapi data pribadi di Amanata.
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                href="/profile"
                @click="dialog.success = false"
                color="red"
                elevation="0"
                outlined
                class="text-capitalize border-radius red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card 
            flat
            min-height="100vh"
            color="transparent">
            <v-card-text
              class="pb-2 d-flex align-center">
              <v-avatar 
                tile
                size="150">
                <v-img 
                  contain
                  style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
                        border: 5px solid #0000000d;
                        border-radius: 10px;"
                  alt="amanata" 
                  :src="form.image_url === '' ? require('@/assets/image/member_default.jpg') : form.image_url"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <div>
                <v-btn
                  @click="sheet.option = true"
                  :disabled="process.file"
                  :loading="process.file"
                  elevation="0"
                  rounded
                  block
                  large
                  color="#0000000d"
                  class="body-1 font-weight-bold text-capitalize d-flex align-center"
                  style="border-radius: 0px 50px 50px 0px;"
                  :style="`color: ${set_color};`">
                  <v-icon
                    size="24"
                    :color="set_color">
                    mdi-camera
                  </v-icon>

                  <span
                    class="ml-1">
                    Ubah Foto
                  </span>
                </v-btn>
              </div>
            </v-card-text>

            <v-card-text
              style="margin-bottom: 160px;">
              <div class="mb-8">
                <ValidationObserver 
                  ref="form">
                  <v-form>
                    <ValidationProvider 
                      name="name" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Nama Lengkap
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. John Doe"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.name"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="username" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Username
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. johndoe"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.username"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="email" 
                      rules="required|email" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Email
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. johndoe@gmail.com"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.email"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="mobilephone" 
                      rules="required|min:8|max:13"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Telepon
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        min="0"
                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. 081332XXXXXX"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.mobilephone"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="Tempat Lahir" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        Tempat Lahir
                        <span>
                          (boleh dikosongi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. Yogyakarta"
                        :append-icon="valid && form.birthplace !== '' ? 'mdi-check-circle-outline' : ''"
                        v-model="form.birthplace"
                        :success="valid && form.birthplace !== ''"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <div>
                      <div class="body-2 mb-2">
                        Tanggal Lahir
                        <span>
                          (boleh dikosongi)
                        </span>
                      </div>

                      <v-menu
                        ref="birthdate"
                        min-width="290px"
                        max-width="290px"
                        transition="scale-transition"
                        offset-y
                        :nudge-right="40"
                        :close-on-content-click="false"
                        v-model="picker.birthdate">
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider 
                            name="Tanggal Lahir" 
                            v-slot="{ errors, valid }">
                            <v-text-field
                              v-on:keyup.enter="save"
                              readonly
                              class="no_box"
                              solo
                              :color="set_color"
                              placeholder="Contoh. 1992-12-12"
                              
                              :append-icon="valid && form.birthdate !== '' ? 'mdi-check-circle-outline' : ''"

                              clearable
                              @click:clear="form.birthdate = ''"
                              v-on="on"
                              v-bind="attrs"

                              v-model="form.birthdate"
                              :success="valid && form.birthdate !== ''"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>
                        </template>

                        <v-date-picker 
                          :color="set_color"
                          v-model="form.birthdate"
                          @input="picker.birthdate = false">
                        </v-date-picker>
                      </v-menu>
                    </div>

                    <ValidationProvider 
                      name="Jenis Kelamin" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        Jenis Kelamin
                        <span>
                          (boleh dikosongi)
                        </span>
                      </div>

                      <v-select
                        v-on:keyup.enter="save"
                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. Laki-Laki"
                        
                        :items="[
                          {
                            text: 'Laki-Laki',
                            value: 'Laki-Laki'
                          },
                          {
                            text: 'Perempuan',
                            value: 'Perempuan'
                          }
                        ]"
                        item-text="text"
                        item-value="value"
                        :item-color="set_color"

                        :append-icon="valid && form.gender !== '' ? 'mdi-check-circle-outline' : ''"
                        v-model="form.gender"
                        :success="valid && form.gender !== ''"
                        :error-messages="errors"
                        required>
                      </v-select>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="Golongan Darah" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        Golongan Darah
                        <span>
                          (boleh dikosongi)
                        </span>
                      </div>

                      <v-select
                        v-on:keyup.enter="save"
                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. O"
                        
                        :items="[
                          {
                            text: 'O',
                            value: 'O'
                          },
                          {
                            text: 'A',
                            value: 'A'
                          },
                          {
                            text: 'B',
                            value: 'B'
                          },
                          {
                            text: 'AB',
                            value: 'AB'
                          }
                        ]"
                        item-text="text"
                        item-value="value"
                        :item-color="set_color"

                        :append-icon="valid && form.blood_group !== '' ? 'mdi-check-circle-outline' : ''"
                        v-model="form.blood_group"
                        :success="valid && form.blood_group !== ''"
                        :error-messages="errors"
                        required>
                      </v-select>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="address" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Alamat
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-textarea
                        v-on:keyup.enter="save"
                        class="no_box"
                        solo
                        :color="set_color"
                        rows="3"
                        placeholder="Contoh. Kradenan, Trucuk, Klaten"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.address"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-textarea>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="NIK" 
                      rules="min:16|max:16" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        NIK
                        <span>
                          (boleh dikosongi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        class="no_box"
                        type="number"
                        solo
                        :color="set_color"
                        placeholder="Contoh. 3310062XXXXXX"
                        :append-icon="valid && form.nik !== '' ? 'mdi-check-circle-outline' : ''"
                        v-model="form.nik"
                        :success="valid && form.nik !== ''"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="NPWP" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        NPWP
                        <span>
                          (boleh dikosongi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. 00.000.000.0-000.000"
                        :append-icon="valid && form.npwp !== '' ? 'mdi-check-circle-outline' : ''"
                        v-model="form.npwp"
                        :success="valid && form.npwp !== ''"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="BPJS Kesehatan" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        BPJS Kesehatan
                        <span>
                          (boleh dikosongi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. 492010XXXXX"
                        :append-icon="valid && form.bpjs_kes !== '' ? 'mdi-check-circle-outline' : ''"
                        v-model="form.bpjs_kes"
                        :success="valid && form.bpjs_kes !== ''"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="BPJS Ketenagakerjaan" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        BPJS Ketenagakerjaan
                        <span>
                          (boleh dikosongi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. 324010XXXXX"
                        :append-icon="valid && form.bpjs_tk !== '' ? 'mdi-check-circle-outline' : ''"
                        v-model="form.bpjs_tk"
                        :success="valid && form.bpjs_tk !== ''"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="Jumlah Tanggungan" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        Jumlah Tanggungan
                        <span>
                          (boleh dikosongi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        class="no_box"
                        type="number"
                        solo
                        :color="set_color"
                        placeholder="Contoh. 3"
                        :append-icon="valid && form.jumlah_tanggungan !== '' ? 'mdi-check-circle-outline' : ''"
                        v-model="form.jumlah_tanggungan"
                        :success="valid && form.jumlah_tanggungan !== ''"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <div
                      class="body-2 font-weight-bold black--text mb-2">
                      #Akun Bank
                    </div>

                    <ValidationProvider 
                      name="Nama Bank" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        Nama Bank
                        <span>
                          (boleh dikosongi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. BRI"
                        :append-icon="valid && form.account_bank_name !== '' ? 'mdi-check-circle-outline' : ''"
                        v-model="form.account_bank_name"
                        :success="valid && form.account_bank_name !== ''"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="Nomor Rekening" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        Nomor Rekening
                        <span>
                          (boleh dikosongi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        class="no_box"
                        type="number"
                        solo
                        :color="set_color"
                        placeholder="Contoh. XXXXXXXX"
                        :append-icon="valid && form.account_number !== '' ? 'mdi-check-circle-outline' : ''"
                        v-model="form.account_number"
                        :success="valid && form.account_number !== ''"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="Atas Nama Rekening" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        Atas Nama Rekening
                        <span>
                          (boleh dikosongi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. John Doe"
                        :append-icon="valid && form.account_name !== '' ? 'mdi-check-circle-outline' : ''"
                        v-model="form.account_name"
                        :success="valid && form.account_name !== ''"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>

                <div v-if="message.error.length > 0"
                  class="text-center mt-2 mb-6">
                  <div
                    class="error--text d-flex align-start">
                    <v-icon size="18" color="red" class="mt-1">mdi-alert-circle-outline</v-icon>
                    
                    <span
                      class="ml-1">
                      {{ message.error }}
                    </span>
                  </div>
                </div>
              </div>
            </v-card-text>

            <v-card v-if="!sheet.option"
              flat
              color="#FFFFFF"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row 
                  align="center">
                  <v-col 
                    cols="3" 
                    class="pb-0">
                    <v-img 
                      contain
                      alt="amanata" 
                      :src="require('@/assets/image/profile/profile.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col 
                    cols="9" 
                    class="black--text pb-0">
                    <div>
                      <div 
                        class="body-2 line-text-second black--text" 
                        style="opacity: 60%;">
                        Ubah data diri Anda untuk informasi akun di layanan kami, kemudian klik <b class="font-italic">Simpan</b>.
                      </div>
                    </div>
                  </v-col>
                </v-row>
                
                <v-row 
                  class="mt-4">
                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn
                      :disabled="process.form"
                      :loading="process.form"
                      v-on:keyup.enter="save"
                      @click="save"
                      block
                      large
                      color="#348d37"
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize border-radius white--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn 
                      @click="$emit('close', false)"
                      :disabled="process.form"
                      block
                      large
                      color="red"
                      outlined
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize border-radius red--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>

          <v-bottom-sheet
            v-model="sheet.option"
            hide-overlay>
            <v-card
              flat
              color="#fff"
              class="d-flex align-center"
              style="border-radius: 30px 30px 0px 0px;">
              <v-card-text
                class="pa-3">
                <v-row 
                  align="center" 
                  justify="center">
                  <!-- CAMERA -->
                  <v-col cols="4" class="text-center">
                    <div @click="sheet.upload = true; selected.type = 'camera';"
                      class="cursor-pointer">
                      <v-avatar 
                        size="50" 
                        class="cursor-pointer" 
                        v-ripple
                        style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                        <v-img 
                          alt="amanata" 
                          :src="require('@/assets/image/camera.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>

                      <div 
                        class="font-weight-bold black--text mt-2">
                        Kamera
                      </div>
                    </div>
                  </v-col>

                  <v-col 
                    cols="4" 
                    class="text-center px-0">
                    <div 
                      class="d-flex align-center">
                      <v-divider 
                        style="border: 2px solid #eaeaea; border-radius: 50px;" />

                      <div 
                        class="mx-2 font-weight-bold"
                        style="opacity: 80%;">
                        Atau
                      </div>

                      <v-divider 
                        style="border: 2px solid #eaeaea; border-radius: 50px;" />
                    </div>
                  </v-col>

                  <!-- FOLDER -->
                  <v-col 
                    cols="4" 
                    class="text-center">
                    <upload-folder 
                      v-model="upload.folder">
                      <div slot="activator">
                        <v-avatar 
                          size="50" 
                          class="cursor-pointer" 
                          v-ripple
                          style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                          <v-img 
                            alt="amanata" 
                            :src="require('@/assets/image/folder.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <div 
                          class="font-weight-bold black--text mt-2">
                          Galeri
                        </div>
                      </div>
                    </upload-folder>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-bottom-sheet>

          <v-bottom-sheet
            v-model="sheet.upload"
            hide-overlay
            persistent>
            <upload-camera v-if="selected.type === 'camera'"
              :sheet="sheet.upload"
              @frame="sheet.upload = $event"
              @input="addCamera($event)"
              @close="sheet.upload = $event; selected.type = '';" />
          </v-bottom-sheet>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dialog: {
          success: false
        },
        selected: {
          type: ''
        },
        sheet: {
          option: false,
          upload: false
        },
        upload: {
          camera: null,
          folder: null
        },
        form: {
          name: '',

          username: '',
          email: '',
          mobilephone: '',

          image_url: '',

          birthplace: '',
          birthdate: '',
          gender: '',
          blood_group: '',

          address: '',

          nik: '',
          npwp: '',
          bpjs_kes: '',
          bpjs_tk: '',
          jumlah_tanggungan: '',

          account_bank_name: '',
          account_number: '',
          account_name: ''
        },

        picker: {
          birthdate: false
        },

        process: {
          form: false,
          file: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      UploadCamera: () => import(/* webpackPrefetch: true */ '@/components/upload/camera.vue'),
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE
              this.sheet.option = false

              this.form.image_url = this.upload.folder.fileuri
            }
          }
        },
        deep: true
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      this.reset()
    },
    methods: {
      addCamera (e) {
        this.process.file = e.process

        if (!e.process) {
          this.form.image_url = e.fileuri

          this.sheet.option = false
        } 
      },
      reset () {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = this.detail

        this.picker = {
          birthdate: false
        }

        this.process.form = false

        this.message.error = ''
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/user/profile/update`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false
              
              this.$emit('close', false)

              Cookie.set('user', JSON.stringify(response.results))

              this.dialog.success = true
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>