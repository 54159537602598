<template>
  <v-app>
    <TopToolbar />
    
    <BottomNav 
      v-if="!firstLoad && $route.path !== '/'" />
  </v-app>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import TopToolbar from "~/components/public/Header.vue"
  import BottomNav from "~/components/Footer.vue"
  import firebase from "firebase/app"
  import "firebase/messaging"
  export default {
    data () {
      return {
        firstLoad: true
      }
    },
    components: {
      TopToolbar,
      BottomNav
    },
    mounted () {
      this.firstLoad = false
    },
    beforeCreate() {
      this.firstLoad = true
    },
    created() {
      this.requestPermission()
    },
    methods: {
      requestPermission () {
        try {
          firebase
            .messaging()
            .requestPermission()
            .then(() => {
              console.log("Notification permission granted")

              firebase
                .messaging()
                .getToken()
                .then((token) => {
                  window.console.log(token)

                  this.$store.commit('member/auth/setFcm', token)
                  Cookie.set('fcm', token)

                  this.receiveMessage()
                })
            })
            .catch((err) => {
              console.log("Unable to get token ", err)
            })
        } catch (e) {
          console.log(e)
        }
      },
      receiveMessage () {
        try {
          firebase
            .messaging()
            .onMessage((payload) => {
              console.log("payload", payload)
            })
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
</script>
