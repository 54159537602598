<template>
  <div class="fill-height pb-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.success"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="amanata"
                  :src="require('@/assets/logo/logo.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold text-capitalize ml-2">
                Export Data Berhasil
              </span>
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-3">
              Terima kasih, Anda telah mengexport data nilai {{ detail.name }}.
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.success = false"
                color="red"
                elevation="0"
                outlined
                class="text-capitalize border-radius red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card-title
              class="font-weight-bold black--text">
              Profil Saya
            </v-card-title>

            <v-card-text
              class="my-4">
              <v-card
                class="border-radius"
                color="#FFFFFF"
                :style="`box-shadow: ${set_color_rgb} 0px 25px 20px -20px;`">
                <v-list 
                  flat 
                  color="transparent">
                  <v-list-item 
                    style="border-radius: 0px;">
                    <v-list-item-avatar 
                      size="45">
                      <v-img 
                        alt="amanata" 
                        :src="detail.image_url === '' ? require('@/assets/image/member_default.jpg') : detail.image_url"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title 
                        class="title font-weight-bold mb-1">
                        Hai, {{ detail.name }}
                      </v-list-item-title>

                      <v-list-item-subtitle 
                        class="d-flex align-center black--text"
                        style="opacity: 60%;">
                        {{ detail.email }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-card-text
                  class="d-flex align-center"
                  :style="`background: ${set_color};`">
                  <div
                    class="caption font-weight-bold white--text line-text-second">
                    <div>
                      Periksa summary skor Anda,
                    </div>

                    <div>
                      selama 1 bulan terakhir
                    </div>
                  </div>

                  <v-spacer />

                  <div>
                    <v-btn
                      @click="sheet.skor = true; fetchSummary();"
                      :loading="process.summary"
                      :disabled="process.summary"
                      color="#FFFFFF40"
                      elevation="0"
                      class="body-2 font-weight-bold white--text text-capitalize"
                      style="border-radius: 8px;">
                      Lihat Nilai
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>

            <v-list 
              flat 
              dense
              class="px-4"
              color="transparent">
              <v-list-item 
                v-for="(item, index) in menu"
                :key="index"
                @click="goTo(index)"
                class="px-0 py-2"
                :style="index < menu.length - 1 ? 'border-bottom: 1px dashed #bbb;' : ''">
                <v-list-item-avatar 
                  size="22"
                  tile
                  class="mr-3">
                  <v-img 
                    contain
                    alt="amanata" 
                    :src="item.icon"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="20"
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title 
                    class="body-2 font-weight-bold"
                    :class="index === menu.length - 1 ? 'red--text' : 'black--text'">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.menu"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title 
              @click="goTo(selected.menu)"
              class="cursor-pointer pa-4"
              style="border-radius: 0px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;"
              :style="`background: ${set_color};`">
              <v-icon 
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span v-if="selected.menu !== null"
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                {{ menu[selected.menu].title }}
              </span>
            </v-card-title>

            <v-card-text 
              class="pa-0">
              <General v-if="selected.menu === 0"
                :detail="detail"
                @close="sheet.menu = $event; fetch();" />

              <Password v-if="selected.menu === 1"
                @close="sheet.menu = $event; fetch();" />

              <History v-if="selected.menu === 2"
                :detail="detail"
                @close="sheet.menu = $event; fetch();" />

              <Document v-if="selected.menu === 3"
                :detail="detail"
                @close="sheet.menu = $event; fetch();" />

              <Documentcompany v-if="selected.menu === 4"
                :detail="detail"
                @close="sheet.menu = $event; fetch();" />

              <Overtime v-if="selected.menu === 5"
                :detail="detail"
                @close="sheet.menu = $event; fetch();" />

              <Arsip v-if="selected.menu === 6"
                :detail="detail"
                @close="sheet.menu = $event; fetch();" />

              <Aset v-if="selected.menu === 7"
                :detail="detail"
                @close="sheet.menu = $event; fetch();" />

              <Scan v-if="selected.menu === 8"
                :detail="detail"
                @close="sheet.menu = $event; selected.menu = null; fetch();" />
            </v-card-text>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.skor"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title 
              @click="sheet.skor = false"
              class="cursor-pointer pa-4"
              style="border-radius: 0px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;"
              :style="`background: ${set_color};`">
              <v-icon 
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span 
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Summary Skor
              </span>
            </v-card-title>

            <v-card-text 
              class="pa-4 pt-16 mt-4">
              <!-- FILTER -->
              <div>
                <v-select
                  class="no_box"
                  solo
                  :color="set_color"

                  placeholder="Contoh. Januari"

                  :items="months"
                  :item-color="set_color"

                  clearable
                  @click:clear="filter.month = ''"

                  @change="fetchSummary()"

                  v-model="filter.month">
                </v-select>

                <v-select
                  class="no_box"
                  solo
                  :color="set_color"

                  placeholder="Contoh. 2023"
                  
                  :item-color="set_color"
                  :items="years"

                  clearable
                  @click:clear="filter.year = ''"

                  @change="fetchSummary()"

                  v-model="filter.year">
                </v-select>
              </div>
              
              <!-- TUGAS UTAMA -->
              <v-card
                flat
                color="transparent"
                class="border-radius mb-6">
                <v-card-title
                  class="white--text py-0"
                  :style="`background: ${set_color};`">
                  <v-row>
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #FFFFFF;">
                      <div
                        class="body-1">
                        Pelaksanaan Tugas Utama
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-1">
                        Bobot
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-card-text
                  class="pa-0"
                  style="border: 1px solid #e0e0e0;">
                  <v-row
                    style="padding: 0px 15px;">
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #e0e0e0;">
                      <div
                        class="body-2 line-text-first black--text d-flex align-start">
                        <div
                          class="mr-1">
                          A.
                        </div>

                        <div>
                          <div
                            class="mb-1">
                            Penilaian Individu (Job Deskripsi)
                          </div>

                          <div
                            style="opacity: 60%;">
                            Kesesuaian pekerjaan yang dilakukan dengan uraian pekerjaan
                          </div>
                        </div>
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-2 black--text">
                        {{ summary.bobot_individu }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-divider
                    style="border-top: 1px solid #e0e0e0;" />

                  <v-row
                    style="padding: 0px 15px;">
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #e0e0e0;">
                      <div
                        class="body-2 line-text-first black--text d-flex align-start">
                        <div
                          class="mr-1">
                          B.
                        </div>

                        <div>
                          <div
                            class="mb-1">
                            Penilaian Atasan Langsung
                          </div>

                          <div
                            style="opacity: 60%;">
                            Hasil nilai kuesioner
                          </div>
                        </div>
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-2 black--text">
                        {{ summary.bobot_atasan_langsung }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-divider
                    style="border-top: 1px solid #e0e0e0;" />

                  <v-row
                    style="padding: 0px 15px;">
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #e0e0e0;">
                      <div
                        class="body-2 line-text-first black--text d-flex align-start">
                        <div
                          class="mr-1">
                          C.
                        </div>

                        <div>
                          <div
                            class="mb-1">
                            Kedisiplinan Karyawan (Presensi)
                          </div>

                          <div
                            style="opacity: 60%;">
                            Jumlah Kehadiran atau presensi
                          </div>
                        </div>
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-2 black--text">
                        {{ summary.bobot_presensi }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-divider
                    style="border-top: 1px solid #e0e0e0;" />

                  <v-row
                    style="padding: 0px 15px;">
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #e0e0e0;">
                      <div
                        class="body-1 font-weight-bold black--text text-center">
                        Bobot Indikator
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-1 font-weight-bold black--text">
                        {{ summary.bobot_tugas_utama }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- TUGAS AIK -->
              <v-card
                flat
                color="transparent"
                class="border-radius mb-6">
                <v-card-title
                  class="white--text py-0"
                  :style="`background: ${set_color};`">
                  <v-row>
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #FFFFFF;">
                      <div
                        class="body-1">
                        Pelaksanaan Kegiatan AIK
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-1">
                        Bobot
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-card-text
                  class="pa-0"
                  style="border: 1px solid #e0e0e0;">
                  <v-row
                    style="padding: 0px 15px;">
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #e0e0e0;">
                      <div
                        class="black--text mb-1">
                        Kegiatan Keagamaan
                      </div>
                      
                      <div
                        class="body-2 line-text-first black--text d-flex align-start"
                        style="opacity: 60%;">
                        <div
                          class="mr-1">
                          1.
                        </div>

                        <div>
                          Pengajian, tadarus, tafsir hadist di unit bisnis atau PT
                        </div>
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-2 black--text">
                        {{ summary.bobot_pengajian }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-divider
                    style="border-top: 1px solid #e0e0e0;" />

                  <v-row
                    style="padding: 0px 15px;">
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #e0e0e0;">
                      <div
                        class="body-2 line-text-first black--text d-flex align-start"
                        style="opacity: 60%;">
                        <div
                          class="mr-1">
                          2.
                        </div>

                        <div>
                          Pengembangan Ekonomi PT UMB
                        </div>
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-2 black--text">
                        {{ summary.bobot_ekonomi }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-divider
                    style="border-top: 1px solid #e0e0e0;" />

                  <v-row
                    style="padding: 0px 15px;">
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #e0e0e0;">
                      <div
                        class="body-2 line-text-first black--text d-flex align-start"
                        style="opacity: 60%;">
                        <div
                          class="mr-1">
                          3.
                        </div>

                        <div>
                          Hafalan Surat Juz 30
                        </div>
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-2 black--text">
                        {{ summary.bobot_hafalan }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-divider
                    style="border-top: 1px solid #e0e0e0;" />

                  <v-row
                    style="padding: 0px 15px;">
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #e0e0e0;">
                      <div
                        class="body-2 line-text-first black--text d-flex align-start"
                        style="opacity: 60%;">
                        <div
                          class="mr-1">
                          4.
                        </div>

                        <div>
                          Keterlibatan sebagai pengurus / peserta non persyarikatan / kegiatan keagamaan (pengurus masjid, pengurus pengajian, peserta pengajian) diluar unit bisnis
                        </div>
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-2 black--text">
                        {{ summary.bobot_keterlibatan_pengurus }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-divider
                    style="border-top: 1px solid #e0e0e0;" />

                  <v-row
                    style="padding: 0px 15px;">
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #e0e0e0;">
                      <div
                        class="body-1 font-weight-bold black--text text-center">
                        Bobot Indikator
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-1 font-weight-bold black--text">
                        {{ summary.bobot_kegiatan_aik }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- TUGAS TAMBAHAN -->
              <v-card
                flat
                color="transparent"
                class="border-radius mb-6">
                <v-card-title
                  class="white--text py-0"
                  :style="`background: ${set_color};`">
                  <v-row>
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #FFFFFF;">
                      <div
                        class="body-1">
                        Penunjang (Tugas Tambahan)
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-1">
                        Bobot
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-card-text
                  class="pa-0"
                  style="border: 1px solid #e0e0e0;">
                  <v-row
                    style="padding: 0px 15px;">
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #e0e0e0;">
                      <div
                        class="black--text mb-1">
                        Pengembangan Diri (Objektif)
                      </div>
                      
                      <div
                        class="body-2 line-text-first black--text d-flex align-start"
                        style="opacity: 60%;">
                        <div
                          class="mr-1">
                          1.
                        </div>

                        <div>
                          Sertifikasi Kompetensi
                        </div>
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-2 black--text">
                        {{ summary.bobot_sertifikasi_kompetensi }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-divider
                    style="border-top: 1px solid #e0e0e0;" />

                  <v-row
                    style="padding: 0px 15px;">
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #e0e0e0;">
                      <div
                        class="body-2 line-text-first black--text d-flex align-start"
                        style="opacity: 60%;">
                        <div
                          class="mr-1">
                          2.
                        </div>

                        <div>
                          Pelatihan dengan Sertifikat
                        </div>
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-2 black--text">
                        {{ summary.bobot_pelatihan_sertifikasi }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-divider
                    style="border-top: 1px solid #e0e0e0;" />

                  <v-row
                    style="padding: 0px 15px;">
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #e0e0e0;">
                      <div
                        class="body-2 line-text-first black--text d-flex align-start"
                        style="opacity: 60%;">
                        <div
                          class="mr-1">
                          3.
                        </div>

                        <div>
                          Keterlibatan pegawai di lingkungan sekitar
                        </div>
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-2 black--text">
                        {{ summary.bobot_keterlibatan_pegawai }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-divider
                    style="border-top: 1px solid #e0e0e0;" />

                  <v-row
                    style="padding: 0px 15px;">
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #e0e0e0;">
                      <div
                        class="body-1 font-weight-bold black--text text-center">
                        Bobot Indikator
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-1 font-weight-bold black--text">
                        {{ summary.bobot_tugas_tambahan }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- TOTAL BOBOT -->
              <v-card
                flat
                color="transparent"
                class="border-radius mb-6">
                <v-card-title
                  class="white--text py-0"
                  :style="`background: ${set_color};`">
                  <v-row>
                    <v-col
                      cols="12">
                      <div
                        class="body-1">
                       Jumlah Bobot
                      </div>
                    </v-col>
                  </v-row>

                </v-card-title>

                <v-card-text
                  class="py-0 black--text"
                  style="border: 1px solid #e0e0e0;">
                  <v-row>
                    <v-col
                      cols="9"
                      style="border-right: 1px solid #e0e0e0;">
                      <div
                        class="body-1 font-weight-bold">
                        {{ summary.bobot_label }}
                      </div>
                    </v-col>

                    <v-col
                      cols="3"
                      class="d-flex align-center justify-center">
                      <div
                        class="body-1 font-weight-bold">
                        {{ summary.bobot_total }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="transparent"
                class="border-radius mb-6">
                <v-card-title
                  class="white--text py-0"
                  :style="`background: ${set_color};`">
                  <v-row>
                    <v-col
                      cols="12">
                      <div
                        class="body-1">
                       Catatan
                      </div>
                    </v-col>
                  </v-row>

                </v-card-title>

                <v-card-text
                  class="pt-3 black--text"
                  style="border: 1px solid #e0e0e0;">
                  <span
                    class="line-text-second">
                    {{ summary.summary_note }}
                  </span>
                </v-card-text>
              </v-card>

              <v-btn 
                @click="prints()"
                :disabled="process.print"
                :loading="process.print"
                :color="set_color"
                block
                large
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize mb-2"
                style="border-radius: 10px;">
                <v-icon class="mr-1">mdi-file-pdf-box</v-icon>

                Cetak Nilai
              </v-btn>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  import axios from "axios";
  import moment from "moment"
  import "moment/locale/id"
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          success: false
        },

        sheet: {
          menu: false,

          skor: false
        },

        filter: {
          month: '01',
          year: 2023
        },

        selected: {
          menu: null
        },

        menu: [
          {
            title: 'General',
            icon: require('@/assets/image/profile/profile.png'),
            link: '#'
          },
          {
            title: 'Password',
            icon: require('@/assets/image/profile/password.png'),
            link: '#'
          },
          {
            title: 'Riwayat Gaji',
            icon: require('@/assets/image/profile/history.png'),
            link: '#'
          },
          {
            title: 'Dokumen Pribadi',
            icon: require('@/assets/image/profile/doc_4.png'),
            link: '#'
          },
          {
            title: 'Dokumen Perusahaan',
            icon: require('@/assets/image/profile/doc_5.png'),
            link: '#'
          },
          {
            title: 'Lembur',
            icon: require('@/assets/image/profile/lembur.png'),
            link: '#'
          },
          {
            title: 'Managemen Arsip',
            icon: require('@/assets/image/profile/folders.png'),
            link: '#'
          },
          {
            title: 'Managemen Aset',
            icon: require('@/assets/image/profile/asset.png'),
            link: '#'
          },
          {
            title: 'Scan QR',
            icon: require('@/assets/image/profile/qrscan.png'),
            link: '#'
          },
          // {
          //   title: 'Syarat & Ketentuan',
          //   icon: require('@/assets/image/profile/terms.png'),
          //   link: '#'
          // },
          // {
          //   title: 'Kebijakan Privasi',
          //   icon: require('@/assets/image/profile/privacy.png'),
          //   link: '#'
          // },
          {
            title: 'Keluar',
            icon: require('@/assets/image/profile/logout.png'),
            link: '/logout'
          }
        ],

        detail: {},
        summary: {},

        process: {
          load: false,

          summary: false,

          print: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/profile',
          title: 'Profil Saya',
          description: 'Profil Saya Amanata',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      General: () => import(/* webpackPrefetch: true */ '@/components/profile/general.vue'),
      Password: () => import(/* webpackPrefetch: true */ '@/components/profile/password.vue'),
      History: () => import(/* webpackPrefetch: true */ '@/components/profile/history.vue'),
      Document: () => import(/* webpackPrefetch: true */ '@/components/profile/document.vue'),
      Documentcompany: () => import(/* webpackPrefetch: true */ '@/components/profile/document_company.vue'),
      Overtime: () => import(/* webpackPrefetch: true */ '@/components/profile/overtime.vue'),
      Arsip: () => import(/* webpackPrefetch: true */ '@/components/profile/arsip.vue'),
      Aset: () => import(/* webpackPrefetch: true */ '@/components/profile/aset.vue'),
      Scan: () => import(/* webpackPrefetch: true */ '@/components/profile/scan.vue')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b})`
      },

      months () {
        let numb = new Date().getMonth() + 1
        
        this.filter.month = numb < 10 ? `0${numb}` : `${numb}`

        return [
          {
            text: 'Januari',
            value: '01'
          },
          {
            text: 'Februari',
            value: '02'
          },
          {
            text: 'Maret',
            value: '03'
          },
          {
            text: 'April',
            value: '04'
          },
          {
            text: 'Mei',
            value: '05'
          },
          {
            text: 'Juni',
            value: '06'
          },
          {
            text: 'Juli',
            value: '07'
          },
          {
            text: 'Agustus',
            value: '08'
          },
          {
            text: 'September',
            value: '09'
          },
          {
            text: 'Oktober',
            value: '10'
          },
          {
            text: 'November',
            value: '11'
          },
          {
            text: 'Desember',
            value: '12'
          }
        ]
      },
      years () {
        let max = new Date().getFullYear(), min = max - 10, years = []

        for (let i = max; i >= min; i--) {
          years.push({
            text: i,
            value: i
          })
        }

        return years
      },
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/user/profile/detail`)
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      },

      async fetchSummary () {
        this.process.summary = true

        let params = {
          month: this.filter.month,
          year: this.filter.year
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/user/kpi/score_summary/data`, { params })
        .then((response) => {
          this.process.summary = false

          if (response.status === 200) {
            this.summary = response.results.data
          }
        })
      },
      
      async goTo (index) {
        if (this.menu[index].link === '/logout') {
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/user/auth/logout`, {
            username: this.user.username
          })
          .then((response) => {
            if (response.status === 200) {
              Cookie.remove('token')
              Cookie.remove('user')
              Cookie.remove('fcm')

              window.location = '/'
            }
          })
        } else {
          this.selected.menu = this.selected.menu === null ? index : null

          this.sheet.menu = !this.sheet.menu ? true : false
        }
      },

      async prints () {
        this.message.error = ''

        this.process.print = true

        let params = {
          user_id: this.detail.id,
          month: this.filter.month,
          year: this.filter.year
        }

        await axios({
          url: `${process.env.PREFIX_PROXY}kpi/score_summary/print_PDF`,
          method: 'GET',
          responseType: 'blob', // important,
          params: params,
          headers: {
            Authorization: process.env.API_SECRET,
            token: this.token
          }
        }).then((response) => {
          let res = response

          if (res.status === 200) {
            this.process.print = false
            
            this.dialog.success = true
            // this.message.success = 'Berhasil mengeksport data'

            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            
            link.href = url
            link.setAttribute('download', `Amanata-${moment().format('DD-MMMM-YYYY', 'id')}-${moment().format('HH:mm:ss', 'id')}.pdf`)
            document.body.appendChild(link)
            link.click()
          } else {
            this.process.print = false
            this.message.error = res.message
          }
        })
      }
    }
  }
</script>