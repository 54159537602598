<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat
            color="transparent">
            <v-card-text
              class="pb-1 d-flex align-center">
              <div v-if="selected.par_id !== 0"
                class="mr-4">
                <v-btn
                  @click="toBack()"
                  elevation="0"
                  min-width="48"
                  min-height="48"
                  style="border-radius: 10px;"
                  color="#f0f0f0"
                  class="body-2 text-capitalize font-weight-bold d-flex align-center px-0">
                  <v-icon>mdi-arrow-u-left-top-bold</v-icon>
                </v-btn>
              </div>
              
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                class="no_box"
                solo
                :color="set_color"
                single-line
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <div
                class="d-flex align-center ml-4">
                <v-btn
                  @click="selected.menu = selected.menu === 'table' ? 'card' : 'table'"

                  color="#f0f0f0"
                  elevation="0"
                  min-width="48"
                  min-height="48"
                  class="text-capitalize px-0"

                  style="border-radius: 10px;">
                  <v-icon>
                    {{ selected.menu === 'table' ? 'mdi-format-list-bulleted' : 'mdi-apps' }}
                  </v-icon>
                </v-btn>
              </div>
            </v-card-text>

            <v-card-text>
              <div v-if="process.load || check_data('FOLDER').length > 0"
                class="body-2 font-weight-bold">
                Folder
              </div>

              <v-row v-if="process.load">
                <v-col
                  :cols="selected.menu === 'table' ? 6 : 12"
                  v-for="(item, index) in 4"
                  :key="index"
                  :class="selected.menu === 'table' ? '' : 'pb-0'">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.load"
                    type="list-item-three-line">
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <v-row v-if="!process.load">
                <v-col
                  :cols="selected.menu === 'table' ? 6 : 12"
                  v-for="(item, index) in list"
                  :key="index"
                  v-show="item.type === 'FOLDER'"
                  :class="selected.menu === 'table' ? '' : 'pb-0'">
                  <v-list v-if="!process.load && list.length > 0"
                    class="pa-0"
                    color="#f0f0f0"
                    style="border-radius: 10px;">
                    <v-list-item
                      @click="toLink(index)"
                      class="px-2">
                      <v-list-item-avatar
                        class="my-1 ml-0 mr-2">
                        <v-icon>mdi-folder</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title 
                          class="body-2 text-capitalize"
                          style="font-weight: 500;">
                          {{ item.title }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <!-- <v-list-item-action
                        class="ma-0 ml-2">
                        <v-menu
                          transition="slide-x-transition"
                          bottom
                          right
                          offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"

                              icon>
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list
                            dense>
                            <v-list-item
                              v-for="(item_action, index_action) in menu_action.folder"
                              :key="index_action"
                              @click="toAction(item_action.text, list[index])"
                              class="pl-0 pr-3">
                              <v-list-item-avatar
                                class="ma-0">
                                <v-icon
                                  size="20">
                                  {{ item_action.icon }}
                                </v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title
                                  class="body-2 text-capitalize">
                                  {{ item_action.text }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action> -->
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>

              <div v-if="process.load || check_data('FILE').length > 0"
                class="body-2 font-weight-bold"
                :class="selected.menu === 'table' ? 'mt-2' : 'mt-6'">
                File
              </div>

              <v-row v-if="process.load">
                <v-col
                  :cols="selected.menu === 'table' ? 6 : 12"
                  v-for="(item, index) in 4"
                  :key="index"
                  :class="selected.menu === 'table' ? '' : 'pb-0'">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.load"
                    :type="selected.menu === 'table' ? 'image' : 'list-item-three-line'">
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <v-row v-if="!process.load">
                <v-col
                  :cols="selected.menu === 'table' ? 6 : 12"
                  v-for="(item, index) in list"
                  :key="index"
                  v-show="item.type === 'FILE'"
                  :class="selected.menu === 'table' ? '' : 'pb-0'">
                  <v-card v-if="!process.load && list.length > 0"
                    flat
                    color="#f0f0f0"
                    style="border-radius: 10px;">
                    <v-list
                      flat
                      color="transparent"
                      class="pa-0">
                      <v-list-item
                        @click="toLink(index)"
                        class="px-2">
                        <v-list-item-avatar
                          class="my-1 ml-0 mr-2">
                          <v-icon color="red">mdi-file-pdf-box</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title 
                            class="body-2 text-capitalize">
                            {{ item.title }}
                          </v-list-item-title>
                        </v-list-item-content>

                        <!-- <v-list-item-action
                          class="ma-0 ml-2">
                          <v-menu
                            transition="slide-x-transition"
                            bottom
                            right
                            offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"

                                icon>
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list
                              dense>
                              <v-list-item
                                v-for="(item_action, index_action) in menu_action.file"
                                :key="index_action"
                                @click="toAction(item_action.text, list[index])"
                                class="pl-0 pr-3">
                                <v-list-item-avatar
                                  class="ma-0">
                                  <v-icon
                                    size="20">
                                    {{ item_action.icon }}
                                  </v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title
                                    class="body-2 text-capitalize">
                                    {{ item_action.text }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-list-item-action> -->
                      </v-list-item>
                    </v-list>

                    <v-card-text v-if="selected.menu === 'table'"
                      class="pt-0">
                      <v-img
                        height="120"
                        :src="require('@/assets/image/word_default.jpeg')">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-card-text v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center"
                :class="selected.menu === 'table' ? '' : 'mt-3'">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  style="border-radius: 8px;"
                  :style="`color: ${set_color};`">
                  Lihat Lebih Banyak
                </v-btn>
              </v-card-text>

              <Empty v-if="!process.load && pagination.total_page < 1"
                margin="py-16 my-16"
                size="125"
                message="Arsip" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.preview"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <!-- <v-card-title 
              @click="sheet.preview = false"
              class="cursor-pointer pa-4"
              style="border-radius: 0px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;"
              :style="`background: ${set_color};`">
              <div
                class="d-flex align-center">
                <v-icon 
                  color="white">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  class="body-1 font-weight-bold text-capitalize ml-1 white--text one-line">
                  Preview {{ selected.preview.title }}
                </span>
              </div>
            </v-card-title> -->

            <v-card-text v-if="Object.keys(selected.preview).length > 0"
              class="pt-4 px-4 pb-0">
              <div v-if="selected.preview.template_id == 0">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <iframe v-if="selected.preview.url !== ''"
                        :src="`${selected.preview.url}#toolbar=0`" 
                        allowfullscreen
                        frameborder="0" 
                        width="100%"
                        style="border-radius: 10px; border: 2px solid #323639; height: calc(100vh - 88px);">
                      </iframe>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 1">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <v-card-title
                        class="d-flex justify-center black--text text-uppercase mb-4"
                        style="text-transform: uppercase !important; margin-bottom: 16px !important; display: flex !important; justify-content: center !important; font-size: 1.25rem; font-weight: 500; line-height: 2rem; padding: 16px ">
                        {{ selected.preview.template_meta_json_object.judul }}
                      </v-card-title>

                      <v-card-text
                        class="black--text">
                        <v-row>
                          <v-col
                            cols="7">
                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                No.
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.nomor !== '' ? selected.preview.template_meta_json_object.nomor : '__________' }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Hal
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.judul !== '' ? selected.preview.template_meta_json_object.judul : '__________' }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Lampiran
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.lampiran !== '' ? selected.preview.template_meta_json_object.lampiran : '__________' }}
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col
                            cols="5">
                            <div
                              class="text-right"
                              style="text-align: right !important;">
                              Yogyakarta, {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
                            </div>
                          </v-col>
                        </v-row>

                        <div
                          class="mt-6"
                          style="margin-top: 24px !important;">
                          <div
                            class="mb-2"
                            style="margin-bottom: 8px !important;">
                            Yang bertandatangan di bawah ini:
                          </div>

                          <div
                            class="px-8"
                            style="padding-right: 32px !important; padding-left: 32px !important;">
                            <v-row>
                              <v-col
                                cols="2"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Nama
                              </v-col>

                              <v-col
                                cols="10"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.nama_1 !== '' ? selected.preview.template_meta_json_object.nama_1 : '__________' }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col
                                cols="2"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Jabatan
                              </v-col>

                              <v-col
                                cols="10"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.jabatan_1 !== '' ? selected.preview.template_meta_json_object.jabatan_1 : '__________' }}
                              </v-col>
                            </v-row>
                          </div>
                        </div>

                        <div
                          class="mt-2"
                          style="margin-top: 8px !important;">
                          <div
                            class="mb-2"
                            style="margin-bottom: 8px !important;">
                            Dengan ini menerangkan bahwa yang bersangkutan di bawah ini:
                          </div>

                          <div
                            class="px-8"
                            style="padding-right: 32px !important; padding-left: 32px !important;">
                            <v-row>
                              <v-col
                                cols="2"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Nama
                              </v-col>

                              <v-col
                                cols="10"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.nama_2 !== '' ? selected.preview.template_meta_json_object.nama_2 : '__________' }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col
                                cols="2"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Alamat
                              </v-col>

                              <v-col
                                cols="10"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                <div
                                  class="d-flex align-start"
                                  style="display: flex !important; align-items: flex-start !important;">
                                  <div>
                                    :
                                  </div>

                                  <div
                                    class="ml-1"
                                    style="margin-left: 4px !important;">
                                    {{ selected.preview.template_meta_json_object.alamat_2 !== '' ? selected.preview.template_meta_json_object.alamat_2 : '__________' }}
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        </div>

                        <div
                          class="mt-8"
                          style="margin-top: 32px !important;">
                          <div
                            class="mb-2"
                            style="margin-bottom: 8px !important;">
                            Karyawan tersebut benar telah bekerja di {{ selected.preview.template_meta_json_object.unit !== '' ? selected.preview.template_meta_json_object.unit : '__________' }} sejak
                            <span v-if="selected.preview.template_meta_json_object.tanggal_mulai !== '' && selected.preview.template_meta_json_object.tanggal_mulai !== null">
                              {{ selected.preview.template_meta_json_object.tanggal_mulai | fulldate }} 
                            </span>

                            <span v-else>
                              __________
                            </span>
                            sampai dengan
                            <span v-if="selected.preview.template_meta_json_object.tanggal_selesai !== '' && selected.preview.template_meta_json_object.tanggal_selesai !== null">
                              {{ selected.preview.template_meta_json_object.tanggal_selesai | fulldate }} 
                            </span>

                            <span v-else>
                              __________
                            </span>
                            Adapun jabatan terakhir saudara adalah sebagai {{ selected.preview.template_meta_json_object.jabatan_2 !== '' ? selected.preview.template_meta_json_object.jabatan_2 : '__________' }}.
                          </div>

                          <div
                            class="mb-2"
                            style="margin-bottom: 8px !important;">
                            Selama bekerja di {{ selected.preview.template_meta_json_object.unit !== '' ? selected.preview.template_meta_json_object.unit : '__________' }}, Saudara telah memberikan dedikasi dan loyalitasnya yang besar terhadap perusahaan serta tak pernah sekalipun melakukan hal – hal yang merugikan perusahaan. Dalam hal ini saudara {{ selected.preview.template_meta_json_object.nama_2 !== '' ? selected.preview.template_meta_json_object.nama_2 : '__________' }} mengundurkan diri karena {{ selected.preview.template_meta_json_object.alasan !== '' ? selected.preview.template_meta_json_object.alasan : '__________' }}.
                          </div>

                          <div
                            class="mb-2"
                            style="margin-bottom: 8px !important;">
                            Kami sangat berterimakasih atas kerja kerasnya selama ini dan mengharapkan saudara dapat sukses pada masa mendatang.
                          </div>

                          <div>
                            Demikian surat paklaring ini kami buat agar dapat dipergunakan dengan semestinya. Atas perhatiannya, kami ucapkan terimakasih.
                          </div>

                          <v-row
                            justify="end"
                            class="mt-8"
                            style="display: flex; flex-wrap: wrap; flex: 1 1 auto; justify-content: flex-end !important; margin-top: 32px !important;">
                            <v-col
                              cols="6"
                              class="text-center"
                              style="text-align: center !important;">
                              <div>
                                Hormat Kami,
                              </div>

                              <div
                                class="mb-16"
                                style="margin-bottom: 64px !important;">
                                {{ selected.preview.template_meta_json_object.jabatan_1 !== '' ? selected.preview.template_meta_json_object.jabatan_1 : '__________' }}
                              </div>

                              <div>
                                {{ selected.preview.template_meta_json_object.nama_1 !== '' ? selected.preview.template_meta_json_object.nama_1 : '__________' }}
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card-text>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 4">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <v-card-text
                        class="black--text py-2">
                        <v-row>
                          <v-col
                            cols="7">
                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Nomor
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.nomor !== '' ? selected.preview.template_meta_json_object.nomor : '__________' }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Lampiran
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.lampiran !== '' ? selected.preview.template_meta_json_object.lampiran : '__________' }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Hal
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : <span style="font-weight: 500;">{{ selected.preview.template_meta_json_object.hal !== '' ? selected.preview.template_meta_json_object.hal : '__________' }}</span>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <div
                          class="mt-6"
                          style="margin-top: 24px !important;">
                          <div
                            style="margin-bottom: 1px;">
                            Kepada Yth.
                          </div>

                          <div
                            style="margin-bottom: 1px;">
                            {{ selected.preview.template_meta_json_object.nama_2 !== '' ? selected.preview.template_meta_json_object.nama_2 : '__________' }}
                          </div>

                          <div
                            style="margin-bottom: 1px;">
                            {{ selected.preview.template_meta_json_object.jabatan_2 !== '' ? selected.preview.template_meta_json_object.jabatan_2 : '__________' }}
                          </div>

                          <div
                            style="margin-bottom: 1px;">
                            di tempat.
                          </div>
                        </div>

                        <div
                          class="mt-10"
                          style="margin-top: 40px !important;">
                          <v-img
                            contain
                            width="175"
                            height="auto"
                            alt="umb" 
                            :src="require('@/assets/image/assalam.jpeg')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <div
                            class="my-4"
                            style="margin-top: 16px !important; margin-bottom: 16px !important;">
                            <div
                              class="mb-4"
                              style="text-indent: 30px; margin-bottom: 16px !important;">
                              Puji Syukur kehadirat Allah SWT yang telah melimpahkan berkah rahmat-Nya dan kesehatan kepada kita semua sehingga kita masih diberikan kesempatan melakukan aktivitas sehari-hari. Sholawat serta salam tidak lupa selalu kita haturkan pada junjungan kita Nabi Muhammad SAW, para sahabat dan para pengikut-Nya sebagai tauladan sampai akhir zaman. Aamiin. 
                            </div>

                            <div
                              class="mb-4"
                              style="text-indent: 30px; margin-bottom: 16px !important;">
                              Dengan surat ini, kami (PT.  Umat Mandiri Berkemajuan) bermaksud memberitahukan bahwasannya saat ini saudara {{ selected.preview.template_meta_json_object.nama_2 !== '' ? selected.preview.template_meta_json_object.nama_2 : '__________' }} masih menjadi {{ selected.preview.template_meta_json_object.jabatan_2 !== '' ? selected.preview.template_meta_json_object.jabatan_2 : '__________' }}, maka dari itu masih berkewenangan {{ selected.preview.template_meta_json_object.tujuan !== '' ? selected.preview.template_meta_json_object.tujuan : '__________' }} PT. Umat Mandiri Berkemajuan.
                            </div>

                            <div
                              style="text-indent: 30px;">
                              Demikian surat ini kami sampaikan, atas perhatian dan kerjasamanya kami ucapkan terima kasih.
                            </div>
                          </div>

                          <v-img
                            contain
                            width="175"
                            height="auto"
                            alt="umb" 
                            :src="require('@/assets/image/wassalam.jpeg')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>

                        <v-row
                          justify="end"
                          class="mt-8"
                          style="display: flex; flex-wrap: wrap; flex: 1 1 auto; justify-content: flex-end !important; margin-top: 32px !important;">
                          <v-col
                            cols="6"
                            class="text-center"
                            style="text-align: center !important;">
                            <div>
                              Yogyakarta, {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
                            </div>

                            <div
                              class="mb-16"
                              style="margin-bottom: 64px !important;">
                              {{ selected.preview.template_meta_json_object.jabatan_1 !== '' ? selected.preview.template_meta_json_object.jabatan_1 : '__________' }}
                            </div>

                            <div>
                              {{ selected.preview.template_meta_json_object.nama_1 !== '' ? selected.preview.template_meta_json_object.nama_1 : '__________' }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 5">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <v-card-text
                        class="black--text py-2">
                        <v-row>
                          <v-col
                            cols="7">
                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Nomor
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.nomor !== '' ? selected.preview.template_meta_json_object.nomor : '__________' }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Lampiran
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.lampiran !== '' ? selected.preview.template_meta_json_object.lampiran : '__________' }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Hal
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : <span style="font-weight: 500;">{{ selected.preview.template_meta_json_object.hal !== '' ? selected.preview.template_meta_json_object.hal : '__________' }}</span>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <div
                          class="mt-6"
                          style="margin-top: 24px !important;">
                          <div
                            style="margin-bottom: 1px;">
                            Kepada Yth.
                          </div>

                          <div
                            v-for="(item, index) in selected.preview.template_meta_json_object.kepada"
                            :key="index"
                            class="d-flex align-center"
                            style="margin-bottom: 1px; display: flex !important; align-items: center !important;">
                            <span>
                              {{ index + 1 }}.
                            </span>

                            <span
                              class="mx-2"
                              style="margin-right: 8px !important; margin-left: 8px !important;">
                              {{ item.nama !== '' ? item.nama : '__________' }}
                            </span>

                            <span>
                              ({{ item.unit !== '' ? item.unit : '__________' }})
                            </span>
                          </div>

                          <div
                            style="margin-bottom: 1px;">
                            di lingkungan PT. Umat Mandiri Berkemajuan
                          </div>
                        </div>

                        <div
                          class="mt-10"
                          style="margin-top: 40px !important;">
                          <v-img
                            contain
                            width="175"
                            height="auto"
                            alt="umb" 
                            :src="require('@/assets/image/assalam.jpeg')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <div
                            class="my-4"
                            style="margin-top: 16px !important; margin-bottom: 16px !important;">
                            <div
                              class="mb-4"
                              style="text-indent: 30px; margin-bottom: 16px !important;">
                              Dengan hormat, sehubungan dengan memasukinya {{ selected.preview.template_meta_json_object.hari_libur !== '' ? selected.preview.template_meta_json_object.hari_libur : '__________' }}, maka bersama surat ini kami sampaikan informasi sebagai berikut :
                            </div>

                            <div
                              class="mb-4"
                              style="margin-left: 22px; margin-bottom: 16px !important;">
                              <ol
                                type="a">
                                <li
                                  class="pl-2"
                                  style="padding-left: 8px !important;">
                                  Libur menjelang {{ selected.preview.template_meta_json_object.hari_libur !== '' ? selected.preview.template_meta_json_object.hari_libur : '__________' }} dimulai pada tanggal
                                  <span v-if="selected.preview.template_meta_json_object.tanggal_mulai !== '' && selected.preview.template_meta_json_object.tanggal_mulai !== null">
                                    {{ selected.preview.template_meta_json_object.tanggal_mulai | day }}
                                  </span>

                                  <span v-else>
                                    __________
                                  </span>

                                  -

                                  <span v-if="selected.preview.template_meta_json_object.tanggal_selesai !== '' && selected.preview.template_meta_json_object.tanggal_selesai !== null">
                                    {{ selected.preview.template_meta_json_object.tanggal_selesai | fulldate }}
                                  </span>

                                  <span v-else>
                                    __________
                                  </span>
                                </li>

                                <li
                                  class="pl-2"
                                  style="padding-left: 8px !important;">
                                  Kegiatan dan aktifitas di lingkungan PT. UMB mulai aktif kembali pada tanggal
                                  <span v-if="selected.preview.template_meta_json_object.tanggal_masuk !== '' && selected.preview.template_meta_json_object.tanggal_masuk !== null">
                                    {{ selected.preview.template_meta_json_object.tanggal_masuk | fulldate }}
                                  </span>

                                  <span v-else>
                                    __________
                                  </span>
                                </li>

                                <li
                                  class="pl-2"
                                  style="padding-left: 8px !important;">
                                  Informasi atau kebijakan lain {{ selected.preview.template_meta_json_object.informasi_lain !== '' ? selected.preview.template_meta_json_object.informasi_lain : '__________' }}.
                                </li>
                              </ol>
                            </div>

                            <div
                              class="mb-4"
                              style="text-indent: 30px; margin-bottom: 16px !important;">
                              Selanjutnya, mohon bagi pimpinan unit bisnis PT. UMB untuk menyampaikan infromasi tersebut di unit bisnis masing-masing.
                            </div>

                            <div
                              style="text-indent: 30px;">
                              Demikian pemberitahuan ini kami sampaikan atas perhatian dan kerjasamanya diucapkan terima kasih.
                            </div>
                          </div>

                          <v-img
                            contain
                            width="175"
                            height="auto"
                            alt="umb" 
                            :src="require('@/assets/image/wassalam.jpeg')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>

                        <v-row
                          justify="end"
                          class="mt-8"
                          style="display: flex; flex-wrap: wrap; flex: 1 1 auto; justify-content: flex-end !important; margin-top: 32px !important;">
                          <v-col
                            cols="6"
                            class="text-center"
                            style="text-align: center !important;">
                            <div>
                              Yogyakarta, {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
                            </div>

                            <div
                              class="mb-16"
                              style="margin-bottom: 64px !important;">
                              {{ selected.preview.template_meta_json_object.jabatan_1 !== '' ? selected.preview.template_meta_json_object.jabatan_1 : '__________' }}
                            </div>

                            <div>
                              {{ selected.preview.template_meta_json_object.nama_1 !== '' ? selected.preview.template_meta_json_object.nama_1 : '__________' }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 3">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <v-card-text
                        class="black--text py-2">
                        <v-row>
                          <v-col
                            cols="7">
                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Nomor
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.nomor !== '' ? selected.preview.template_meta_json_object.nomor : '__________' }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Lampiran
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.lampiran !== '' ? selected.preview.template_meta_json_object.lampiran : '__________' }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Hal
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0 d-flex align-start"
                                style="padding-top: 0px !important; padding-bottom: 0px !important; display: flex !important; align-items: flex-start !important;">
                                : <span class="ml-1" style="font-weight: 500; margin-left: 4px !important;">{{ selected.preview.template_meta_json_object.hal !== '' ? selected.preview.template_meta_json_object.hal : '__________' }}</span>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <div
                          class="mt-6"
                          style="margin-top: 24px !important;">
                          <div
                            style="margin-bottom: 1px;">
                            Kepada Yth.
                          </div>

                          <div
                            style="margin-bottom: 1px;">
                            Bapak/Ibu/Saudara/i
                          </div>

                          <div
                            v-for="(item, index) in selected.preview.template_meta_json_object.kepada"
                            :key="index"
                            class="d-flex align-center"
                            style="margin-bottom: 1px; display: flex !important; align-items: center !important;">
                            <span>
                              {{ index + 1 }}.
                            </span>

                            <span
                              class="mx-2"
                              style="margin-right: 8px !important; margin-left: 8px !important;">
                              {{ item.nama !== '' ? item.nama : '__________' }}
                            </span>

                            <span>
                              ({{ item.unit !== '' ? item.unit : '__________' }})
                            </span>
                          </div>

                          <div
                            style="margin-bottom: 1px;">
                            di lingkungan PT. Umat Mandiri Berkemajuan
                          </div>
                        </div>

                        <div
                          class="mt-10"
                          style="margin-top: 40px !important;">
                          <div
                            class="font-italic"
                            style="font-weight: 500; font-style: italic !important;">
                            Assalamu’alaikum wr.wb.
                          </div>

                          <div
                            class="my-4"
                            style="margin-top: 16px !important; margin-bottom: 16px !important;">
                            <div
                              class="mb-4"
                              style="text-indent: 30px; margin-bottom: 16px !important;">
                              Puji syukur kita panjatkan kepada Allah SWT yang telah memberikan berkah rahmat-Nya dan kesehatan kepada kita semua sehingga kita masih dapat melakukan aktivitas kita sehari-hari. Sholawat dan salam tidak lupa selalu kita haturkan kepada junjungan kita Nabi Muhammad SAW, para sahabat dan para pengikut-Nya sebagai tauladan sampai akhir zaman. Aamin.
                            </div>

                            <div
                              class="mb-4"
                              style="text-indent: 30px; margin-bottom: 16px !important;">
                              Sehubungan dengan penyempurnaan sistem SDM di PT. UMB, maka bersama surat ini kami bermaksud mengundang Bapak/Ibu pada :
                            </div>

                            <div
                              class="mb-4"
                              style="margin-bottom: 16px !important;">
                              <v-row>
                                <v-col
                                  cols="3"
                                  class="py-0"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                  Hari/Tanggal
                                </v-col>

                                <v-col
                                  cols="9"
                                  class="py-0"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                  : 
                                  <span v-if="selected.preview.template_meta_json_object.hari_tanggal !== '' && selected.preview.template_meta_json_object.hari_tanggal !== null">
                                    {{ selected.preview.template_meta_json_object.hari_tanggal | weekday }}
                                  </span>

                                  <span v-else>
                                    __________
                                  </span>
                                  
                                  ,

                                  <span v-if="selected.preview.template_meta_json_object.hari_tanggal !== '' && selected.preview.template_meta_json_object.hari_tanggal !== null">
                                    {{ selected.preview.template_meta_json_object.hari_tanggal | fulldate }}
                                  </span>

                                  <span v-else>
                                    __________
                                  </span>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col
                                  cols="3"
                                  class="py-0"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                  Pukul
                                </v-col>

                                <v-col
                                  cols="9"
                                  class="py-0"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                  : {{ selected.preview.template_meta_json_object.pukul !== '' ? selected.preview.template_meta_json_object.pukul : '__________' }} s/d Selesai
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col
                                  cols="3"
                                  class="py-0"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                  Tempat
                                </v-col>

                                <v-col
                                  cols="9"
                                  class="py-0 d-flex align-start"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important; display: flex !important; align-items: start !important;">
                                  : 
                                  <span
                                    class="ml-1"
                                    style="margin-left: 4px !important;">
                                    {{ selected.preview.template_meta_json_object.tempat !== '' ? selected.preview.template_meta_json_object.tempat : '__________' }}
                                  </span>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col
                                  cols="3"
                                  class="py-0"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                  Agenda
                                </v-col>

                                <v-col
                                  cols="9"
                                  class="py-0 d-flex align-start"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important; display: flex !important; align-items: start !important;">
                                  : 
                                  <span v-if="selected.preview.template_meta_json_object.agenda !== ''"
                                    class="ml-1 from_html"
                                    style="margin-left: 4px !important;"
                                    v-html="selected.preview.template_meta_json_object.agenda">
                                  </span>

                                  <span v-else
                                    class="ml-1"
                                    style="margin-left: 4px !important;">
                                    __________
                                  </span>
                                </v-col>
                              </v-row>

                              <v-row
                                class="mt-4">
                                <v-col
                                  cols="3"
                                  class="py-0"
                                  style="font-weight: 500; padding-top: 0px !important; padding-bottom: 0px !important;">
                                  Catatan
                                </v-col>

                                <v-col
                                  cols="9"
                                  class="py-0 d-flex align-start"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important; display: flex !important; align-items: start !important;">
                                  : 
                                  <span v-if="selected.preview.template_meta_json_object.catatan !== ''"
                                    class="ml-1 from_html"
                                    style="margin-left: 4px !important;"
                                    v-html="selected.preview.template_meta_json_object.catatan">
                                  </span>

                                  <span v-else
                                    class="ml-1"
                                    style="margin-left: 4px !important;">
                                    __________
                                  </span>
                                </v-col>
                              </v-row>
                            </div>

                            <div
                              style="text-indent: 30px;">
                              Demikian surat undangan ini kami sampaikan, atas perhatian dan kerjasamanya diucapkan terima kasih.
                            </div>
                          </div>

                          <div
                            class="font-italic"
                            style="font-weight: 500; font-style: italic !important;">
                            Wassalamu’alaikum wr.wb.
                          </div>
                        </div>

                        <v-row
                          justify="end"
                          class="mt-8"
                          style="display: flex; flex-wrap: wrap; flex: 1 1 auto; justify-content: flex-end !important; margin-top: 32px !important;">
                          <v-col
                            cols="6"
                            class="text-center"
                            style="text-align: center !important;">
                            <div>
                              Yogyakarta, {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
                            </div>

                            <div
                              class="mb-16"
                              style="margin-bottom: 64px !important;">
                              {{ selected.preview.template_meta_json_object.jabatan_1 !== '' ? selected.preview.template_meta_json_object.jabatan_1 : '__________' }}
                            </div>

                            <div>
                              {{ selected.preview.template_meta_json_object.nama_1 !== '' ? selected.preview.template_meta_json_object.nama_1 : '__________' }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 2">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <v-card-text
                        class="black--text py-2">
                        <v-row>
                          <v-col
                            cols="7">
                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Nomor
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.nomor !== '' ? selected.preview.template_meta_json_object.nomor : '__________' }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Lampiran
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.lampiran !== '' ? selected.preview.template_meta_json_object.lampiran : '__________' }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Hal
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0 d-flex align-start"
                                style="padding-top: 0px !important; padding-bottom: 0px !important; display: flex !important; align-items: flex-start !important;">
                                : <span class="ml-1" style="font-weight: 500; margin-left: 4px !important;">{{ selected.preview.template_meta_json_object.hal !== '' ? selected.preview.template_meta_json_object.hal : '__________' }}</span>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col
                            cols="5">
                            <div
                              class="text-right"
                              style="text-align: right !important;">
                              Yogyakarta, {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
                            </div>
                          </v-col>
                        </v-row>

                        <div
                          class="mt-6"
                          style="margin-top: 24px !important;">
                          <div
                            style="margin-bottom: 1px;">
                            Kepada Yth.
                          </div>

                          <div
                            style="margin-bottom: 1px;">
                            Bapak/Ibu/Saudara/i
                          </div>

                          <div
                            v-for="(item, index) in selected.preview.template_meta_json_object.kepada"
                            :key="index"
                            class="d-flex align-center"
                            style="margin-bottom: 1px; display: flex !important; align-items: center !important;">
                            <span>
                              {{ index + 1 }}.
                            </span>

                            <span
                              class="mx-2"
                              style="margin-right: 8px !important; margin-left: 8px !important;">
                              {{ item.nama !== '' ? item.nama : '__________' }}
                            </span>

                            <span>
                              ({{ item.unit !== '' ? item.unit : '__________' }})
                            </span>
                          </div>

                          <div
                            style="margin-bottom: 1px;">
                            di lingkungan PT. Umat Mandiri Berkemajuan
                          </div>
                        </div>

                        <div
                          class="mt-10"
                          style="margin-top: 40px !important;">
                          <v-img
                            contain
                            width="175"
                            height="auto"
                            alt="umb" 
                            :src="require('@/assets/image/assalam.jpeg')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <div
                            class="my-4"
                            style="margin-top: 16px !important; margin-bottom: 16px !important;">
                            <div
                              class="mb-4"
                              style="text-indent: 30px; margin-bottom: 16px !important;">
                              Puji Syukur kehadirat Allah SWT yang telah melimpahkan berkah rahmat-Nya dan kesehatan kepada kita semua sehingga kita masih diberikan kesempatan melakukan aktivitas sehari-hari. Sholawat serta salam tidak lupa selalu kita haturkan pada junjungan kita Nabi Muhammad SAW, para sahabat dan para pengikut-Nya sebagai tauladan sampai akhir zaman. Aamiin. 
                            </div>

                            <div
                              class="mb-4"
                              style="text-indent: 30px; margin-bottom: 16px !important;">
                              Dengan ini kami bermaksud mengundang Bapak/Ibu/Saudara/i untuk menghadiri acara Pengajian dan Buka Bersama Keluarga Besar PT. Umat Mandiri Berkemajuan, yang insyaAllah akan diselenggarakan pada :
                            </div>

                            <div
                              class="mb-4"
                              style="margin-bottom: 16px !important;">
                              <v-row>
                                <v-col
                                  cols="3"
                                  class="py-0"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                  Hari/Tanggal
                                </v-col>

                                <v-col
                                  cols="9"
                                  class="py-0"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                  : 
                                  <span v-if="selected.preview.template_meta_json_object.hari_tanggal !== '' && selected.preview.template_meta_json_object.hari_tanggal !== null">
                                    {{ selected.preview.template_meta_json_object.hari_tanggal | weekday }}
                                  </span>

                                  <span v-else>
                                    __________
                                  </span>
                                  
                                  ,

                                  <span v-if="selected.preview.template_meta_json_object.hari_tanggal !== '' && selected.preview.template_meta_json_object.hari_tanggal !== null">
                                    {{ selected.preview.template_meta_json_object.hari_tanggal | fulldate }}
                                  </span>

                                  <span v-else>
                                    __________
                                  </span>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col
                                  cols="3"
                                  class="py-0"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                  Pukul
                                </v-col>

                                <v-col
                                  cols="9"
                                  class="py-0"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                  : {{ selected.preview.template_meta_json_object.pukul !== '' ? selected.preview.template_meta_json_object.pukul : '__________' }} s/d Selesai
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col
                                  cols="3"
                                  class="py-0"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                  Tempat
                                </v-col>

                                <v-col
                                  cols="9"
                                  class="py-0 d-flex align-start"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important; display: flex !important; lign-items: flex-start !important;">
                                  : 
                                  <span
                                    class="ml-1"
                                    style="margin-left: 4px !important;">
                                    {{ selected.preview.template_meta_json_object.tempat !== '' ? selected.preview.template_meta_json_object.tempat : '__________' }}
                                  </span>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col
                                  cols="3"
                                  class="py-0"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                  Penceramah
                                </v-col>

                                <v-col
                                  cols="9"
                                  class="py-0"
                                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                  : {{ selected.preview.template_meta_json_object.penceramah !== '' ? selected.preview.template_meta_json_object.penceramah : '__________' }}
                                </v-col>
                              </v-row>
                            </div>

                            <div
                              style="text-indent: 30px;">
                              Demikian surat undangan ini kami sampaikan, atas perhatian dan kerjasamanya kami ucapkan terima kasih.
                            </div>
                          </div>

                          <v-img
                            contain
                            width="175"
                            height="auto"
                            alt="umb" 
                            :src="require('@/assets/image/wassalam.jpeg')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>

                        <v-row
                          justify="end"
                          class="mt-8"
                          style="display: flex; flex-wrap: wrap; flex: 1 1 auto; justify-content: flex-end !important; margin-top: 32px !important;">
                          <v-col
                            cols="6"
                            class="text-center"
                            style="text-align: center !important;">
                            <div>
                              Hormat kami,
                            </div>

                            <div
                              class="mb-16"
                              style="margin-bottom: 64px !important;">
                              {{ selected.preview.template_meta_json_object.jabatan_1 !== '' ? selected.preview.template_meta_json_object.jabatan_1 : '__________' }}
                            </div>

                            <div>
                              {{ selected.preview.template_meta_json_object.nama_1 !== '' ? selected.preview.template_meta_json_object.nama_1 : '__________' }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-btn
                @click="sheet.preview = false"
                elevation="0"
                min-width="40"
                min-height="40"
                style="border-radius: 10px;"
                color="#f0f0f0"
                class="body-2 text-capitalize font-weight-bold d-flex align-center px-0 mb-2">
                <v-icon>mdi-arrow-down-thick</v-icon>
              </v-btn>

              <v-spacer/>

              <v-btn
                color="green"
                elevation="0"
                min-width="40"
                min-height="40"
                style="border-radius: 10px;"
                class="body-2 text-capitalize font-weight-bold d-flex align-center px-0 mb-2"
                :disabled="process.form"
                :loading="process.form"
                @click="exportToPDF()">
                <v-icon 
                  color="white" 
                  style="transform: rotate(-90deg);">
                  mdi-download-circle-outline
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import moment from "moment"
  import "moment/locale/id"
  import html2pdf from 'html2pdf.js'

  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        sheet: {
          preview: false
        },

        search: '',

        selected: {
          par_id: 0,
          par_id_before: [],

          preview: {},

          menu: 'table'
        },

        menu_action: {
          folder: [
            {
              text: 'pindah',
              icon: 'mdi-file-move-outline'
            },
            {
              text: 'share',
              icon: 'mdi-share-outline'
            },
            {
              text: 'ubah',
              icon: 'mdi-circle-edit-outline'
            },
            {
              text: 'hapus',
              icon: 'mdi-delete-circle-outline'
            }
          ],
          file: [
            {
              text: 'pindah',
              icon: 'mdi-file-move-outline'
            },
            {
              text: 'share',
              icon: 'mdi-share-outline'
            },
            {
              text: 'download',
              icon: 'mdi-download-outline'
            },
            {
              text: 'ubah',
              icon: 'mdi-circle-edit-outline'
            },
            {
              text: 'hapus',
              icon: 'mdi-delete-circle-outline'
            }
          ]
        },

        list: [],
        pagination: {},

        limit: 10,

        process: {
          load: false,

          limit: false,

          form: false
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.process.limit = true
        
        this.fetch('limit')
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          limit: this.limit,
          search: this.search,

          par_id: this.selected.par_id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/files/data`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {  
            this.list = response.results.data
            this.pagination = response.results.pagination
          } 
        })
      },

      toLink (index) {
        if (this.list[index].type === 'FOLDER') {
          this.selected.par_id_before.push(this.selected.par_id)
          
          this.selected.par_id = this.list[index].id
  
          this.fetch()
        } else {
          this.sheet.preview = true
          this.selected.preview = this.list[index]
        }
      },

      toBack () {
        this.selected.par_id = this.selected.par_id_before.pop()

        this.fetch()
      },

      check_data (type) {
        return this.list.filter((item) => {
          return item.type === type
        })
      },

      exportToPDF () {
        this.process.form = true

        if (this.selected.preview.template_id != 0) {
          let filename = `Amanata-${moment().format('DD-MMMM-YYYY', 'id')}-${moment().format('HH:mm:ss', 'id')}.pdf`
  
          html2pdf(this.$refs.arsippdf, {
            margin: 0.7,
            filename: filename,
            image: { 
              type: 'jpeg', 
              quality: 0.98
            },
            html2canvas: { 
              dpi: 192, 
              letterRendering: true,
              useCORS: true
            },
            jsPDF: { 
              unit: 'in', 
              format: 'letter', 
              orientation: 'portrait'
            }
          })
  
          setTimeout(() => { 
            this.process.form = false
  
            this.sheet.preview = false
            this.selected.preview = {}
          }, 1000)
        } else {
          window.open(this.selected.preview.url, '_blank')

          this.process.form = false
        }
      }
    }
  }
</script>

<style>
  .from_html p {
    margin-bottom: 0px !important;
  }

  .from_html br {
    display: block !important;
    content: "";
    margin: 10px 0 !important;
  }

  .from_html ul, .from_html ol {
    padding-left: 16px !important;
  }
</style>