<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat
            min-height="100vh"
            color="transparent">
            <v-card-title 
              @click="$emit('close', false); $emit('code', ''); to_close();"
              class="cursor-pointer pa-4"
              style="border-radius: 0px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;"
              :style="`background: ${set_color};`">
              <v-icon 
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Scan QR
              </span>
            </v-card-title>

            <v-card-text 
              class="py-8"
              style="margin-bottom: 120px;">
              <div>
                <ValidationObserver 
                  ref="form">
                  <v-form 
                    class="text-left mt-12">
                    <div
                      style="height: 400px;">
                      <div
                        class="body-1 font-weight-bold black--text text-center">
                        Scan Barcode / QR Kode
                      </div>

                      <v-divider 
                        class="my-2"
                        style="border-top: 1px dashed #e0e0e0;"/>

                      <div
                        class="body-2 text-center mb-6">
                        Silahkan scan gambar dibawah ini:
                      </div>

                      <div
                        style="height: 400px; border-radius: 10px; background: #00000040;">
                        <StreamBarcodeReader
                          ref="scanner"
                          @decode="(a, b, c) => onDecode(a, b, c)"
                          @loaded="() => onLoaded()">
                        </StreamBarcodeReader>
                      </div>

                      <v-row 
                        align="center"
                        class="mt-6">
                        <v-col 
                          cols="3">
                          <v-img 
                            contain
                            alt="amanata" 
                            :src="require('@/assets/image/profile/qrscan.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>

                        <v-col 
                          cols="9">
                          <div>
                            <div 
                              class="body-2 line-text-second black--text" 
                              style="opacity: 60%;">
                              Jika data scan berhasil, maka Anda bisa melihat detail item dari aset yang Anda cari.
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-form>
                </ValidationObserver>
              </div>
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { StreamBarcodeReader } from "vue-barcode-reader";
  export default {
    data () {
      return {
        form: {
          code: ''
        },

        process: {
          form: false
        },
        
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      StreamBarcodeReader
    },
    watch: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      
    },
    methods: {
      onDecode(a, b, c) {
        // console.log(a, b, c)

        // if (this.form.id) clearTimeout(this.form.id)

        // this.form.id = setTimeout(() => {
        //   if (this.form.uuid === a) {
        //     this.form.uuid = ''
        //   }
        // }, 10000)

        this.form.code = a

        if (this.form.code !== '') {
          this.$emit('code', this.form.code)

          this.$emit('close', false)

          this.to_close()
        }
      },
      onLoaded() {
        // console.log('load')

        // console.log(this.$refs.scanner.codeReader.stream)
      },
      to_close () {
        this.$refs.scanner.codeReader.stream.getTracks().forEach(function (track) { track.stop() })
      }
    }
  }
</script>

<style>
  video {
    height: 400px;
    border-radius: 10px;
  }

  .overlay-element {
    border-radius: 10px;
  }
</style>