<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container
            class="py-0">
            <!-- <div
              style="position: absolute; top: 150px; left: 0; right: 0;">
              <v-img 
                contain
                width="175"
                height="175"
                alt="amanata" 
                :src="require('@/assets/logo/logo.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="text-h4 font-weight-bold text-center"
                style="position: relative; top: -25px;"
                :style="`color: ${set_color};`">
                amanata
              </div>
            </div> -->
            
            <v-row
              align="center"
              style="height: 100vh;">
              <v-col
                class="pa-0">
                <v-card  
                  flat
                  color="transparent">
                  <div
                    class="mb-16">
                    <v-img 
                      contain
                      :width="Object.keys(company).length > 0 ? 250 : 175"
                      height="175"
                      alt="amanata"
                      :src="Object.keys(company).length > 0 ? company.logo : require('@/assets/logo/logo.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div v-if="Object.keys(company).length < 1"
                      class="text-h4 font-weight-bold text-center"
                      style="position: relative; top: -25px;"
                      :style="`color: ${set_color};`">
                      {{ Object.keys(company).length > 0 ? company.company_name : 'amanata' }}
                    </div>
                  </div>
                  
                  <v-card-text>
                    <ValidationObserver 
                      ref="form">
                      <v-form>
                        <ValidationProvider 
                          name="username" 
                          rules="required" 
                          v-slot="{ errors }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            class="no_box"
                            solo
                            :color="set_color"
                            placeholder="Username"
                            prepend-inner-icon="mdi-account-outline"

                            v-model="form.username"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>

                        <ValidationProvider 
                          name="password" 
                          rules="required|min:8|max:20" 
                          v-slot="{ errors }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            class="no_box"
                            solo
                            :color="set_color"
                            placeholder="Password"
                            :type="show ? 'text' : 'password'"
                            prepend-inner-icon="mdi-lock-outline"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show = !show"
                            :counter="20"
                            
                            v-model="form.password"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </v-form>
                    </ValidationObserver>

                    <div v-if="message.error.length > 0"
                      class="text-center mt-4">
                      <div
                        class="error--text d-flex align-start">
                        <v-icon size="18" color="red" class="mt-1">mdi-alert-circle-outline</v-icon>
                        
                        <span
                          class="ml-1">
                          {{ message.error }}
                        </span>
                      </div>
                    </div>
                  </v-card-text>

                  <v-card-text>
                    <div 
                      class="text-center mb-6">
                      <v-btn
                        v-on:keyup.enter="save"
                        :disabled="process.form"
                        :loading="process.form"
                        @click="save"
                        block
                        x-large
                        width="170"
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize border-radius btn_auth"
                        :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                        <div
                          class="btn_auth_in py-2"
                          :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                          Masuk
                        </div>
                      </v-btn>
                    </div>

                    <div
                      class="body-2 black--text text-center"
                      style="opacity: 60%;">
                      Powered by Amanata
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        show: false,
        form: {
          username: '',
          password: '',
          device: 'web',
          fcm_token: ''
        },
        process: {
          load: false,
          form: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/',
          title: 'Login',
          description: 'Login Amanata',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      token() {
        return this.$store.state.member.auth.token
      },
      company () {
        return this.$store.state.header.config.company
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r+35}, ${g+37}, ${b}, 0.97)`
      }
    },
    mounted () {
      if (Cookie.get().token !== undefined) window.location = '/home'
    },
    methods: {
      async save () {
        this.message.error = ''

        this.form.fcm_token = this.fcm

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/user/auth/login`, this.form)
          .then((response) => {
            if (response.status === 200) {
              Cookie.set('token', response.results.token)
              Cookie.set('user', JSON.stringify(response.results.data))
              Cookie.set('popup', true)

              window.location = '/home'
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>